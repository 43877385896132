import React from 'react';
import {
  AiOutlineAudio,
  AiOutlineAudioMuted,
  AiOutlineClose,
  AiOutlinePlusCircle,
  AiOutlineVideoCameraAdd,
} from 'react-icons/ai';
import {
  BiCamera,
  BiCog,
  BiTrashAlt,
  BiEdit,
  BiFilter,
  BiInfoCircle,
  BiLoader,
  BiMicrophone,
  BiMoney,
  BiArrowBack,
  BiStar,
  BiShow,
  BiHome,
  BiPhoneCall,
  BiPhone,
  BiCloudUpload,
  BiVideo,
  BiVideoOff,
  BiCheck,
  BiCheckDouble,
  BiUser,
  BiHomeAlt,
  BiPlay,
  BiStopCircle,
  BiPauseCircle,
  BiSearch,
  BiBuilding,
  BiVideoRecording,
  BiTargetLock,
  BiMessageDots
} from 'react-icons/bi';
import { BsChatDots } from 'react-icons/bs';
import { FaCloudDownloadAlt, FaUsers, FaYoutube, FaMicrophone} from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { GiMagnifyingGlass } from 'react-icons/gi';
import { IoWarning } from 'react-icons/io5';
import { IconType } from 'react-icons/lib';

export const SmsIcon: IconType = props=> {
  return <BiMessageDots {...props}/>;
}

export const VideoPlayerIcon: IconType = props => {
  return <FaYoutube {... props}/>
}

export const MicFillIcon: IconType = props => {
  return <FaMicrophone {...props}/>;
}

export const TargetIcon: IconType = props => {
  return <BiTargetLock {...props}/>
}


export const AddIcon: IconType = props => {
  return <AiOutlinePlusCircle {...props} />;
};

export const AnalyzeIcon: IconType = props => {
  return <GiMagnifyingGlass {...props} />;
};

export const CameraIcon: IconType = props => {
  return <BiCamera {...props} />;
};

export const ConfigIcon: IconType = props => {
  return <BiCog {...props} />;
};

export const DeleteIcon: IconType = props => {
  return <BiTrashAlt {...props} />;
};

export const EditIcon: IconType = props => {
  return <BiEdit {...props} />;
};

export const FilterIcon: IconType = props => {
  return <BiFilter {...props} />;
};

export const InfoIcon: IconType = props => {
  return <BiInfoCircle {...props} />;
};

export const LoadingIcon: IconType = props => {
  return <BiLoader {...props} />;
};

export const MessageIcon: IconType = props => {
  return <BsChatDots {...props} />;
};

export const MicIcon: IconType = props => {
  return <BiMicrophone {...props} />;
};

export const ReceiveMoneyIcon: IconType = props => {
  return <BiMoney {...props} />;
};

export const UploadIcon: IconType = props => {
  return <BiCloudUpload {...props} />;
};

export const MoneyIcon: IconType = props => {
  return <BiMoney {...props} />;
};

export const ReturnIcon: IconType = props => {
  return <BiArrowBack {...props} />;
};

export const SearchIcon: IconType = props => {
  return <BiSearch {...props} />;
};

export const StarIcon: IconType = props => {
  return <BiStar {...props} />;
};

export const VisualizeIcon: IconType = props => {
  return <BiShow {...props} />;
};

export const CloseIcon: IconType = props => {
  return <AiOutlineClose {...props} />;
};

export const HomeIcon: IconType = props => {
  return <BiHome {...props} />;
};

export const PhoneIcon: IconType = props => {
  return <BiPhone {...props} />;
};

export const PhoneCallIcon: IconType = props => {
  return <BiPhoneCall {...props} />;
};

export const VideoDisabledIcon: IconType = props => {
  return <BiVideoOff {...props} />;
};
export const VideoIcon: IconType = props => {
  return <BiVideo {...props} />;
};

export const CreateVideoIcon: IconType = props => {
  return <AiOutlineVideoCameraAdd {...props} />;
};

export const AudioMutedIcon: IconType = props => {
  return <AiOutlineAudioMuted {...props} />;
};
export const AudioIcon: IconType = props => {
  return <AiOutlineAudio {...props} />;
};

export const GoogleIcon: IconType = props => {
  return <FcGoogle {...props} />;
};

export const CheckIcon: IconType = props => {
  return <BiCheck {...props} />;
};
export const CheckDoubleIcon: IconType = props => {
  return <BiCheckDouble {...props} />;
};

export const UserIcon: IconType = props => {
  return <BiUser {...props} />;
};
export const UserGroupIcon: IconType = props => {
  return <FaUsers {...props} />;
};

export const PlayIcon: IconType = props => {
  return <BiPlay {...props} />;
};

export const StopIcon: IconType = props => {
  return <BiStopCircle {...props} />;
};
export const PauseIcon: IconType = props => {
  return <BiPauseCircle {...props} />;
};

export const CompanyIcon: IconType = props => {
  return <BiBuilding {...props} />;
};

export const WarnIcon: IconType = props => {
  return <IoWarning {...props} />;
};

export const RecordIcon: IconType = props => {
  return <BiVideoRecording {...props} />;
};

export const DownloadRecordsIcon: IconType = props => {
  return <FaCloudDownloadAlt {...props} />;
};
